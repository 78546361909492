import request from '../utils/request'

// API-F41 - [BalanceController] balance/list 收入情況分析列表
export function getEarnings (params) {
  return request({
    url: `/balance/list`,
    method: 'get',
    params
  })
}

// API-F41 - [BalanceController] balance/detail 收入情況 detail
export function getBalanceDetail (params) {
  return request({
    url: `/balance/detail`,
    method: 'get',
    params
  })
}

// API-F40 - [BalanceController] balance/apply 提款申請
export function addApply (data) {
  return request({
    url: `/balance/apply`,
    method: 'post',
    data
  })
}
