<template>
  <div class="col-lg-3 col-md-3">
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('label.incomeAnalysis')}}</h4>
      </template>
      <template v-slot:body>
        <div class="earning-ranking">
          <p class="bg-soft-primary ps-3 pe-3 pt-2 pb-2 rounded">
            <span class="font-size-11 text-warning"><i class="fa fa-star"></i></span>
            {{$t('label.beforeRevenueForAllCreators')}} {{row.user_rank}}
          </p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex m-1">
            <span>{{$t('label.balance')}}</span>
          </div>
          <div class="d-flex align-items-center">
            <h6>{{row.cash}}</h6>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex m-1">
            <span>{{$t('label.referralRevenue')}}</span>
          </div>
          <div class="d-flex align-items-center">
            <h6>{{row.point}}</h6>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex m-1">
            <span>{{$t('label.referralLink')}}</span>
          </div>
          <div class="d-flex align-items-center">
            <a @click="onCopyText(ref)" href="javascript:void(0)">{{$t('label.copyLink')}}</a>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex m-1" style="word-break:break-all">
            <h6>{{ref}}</h6>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="d-flex m-1">
            <router-link :to="{ name: 'user.referrals-user-list' }" class=" ">
              {{$t('label.viewRecommendedUsers')}}
            </router-link>
          </div>
        </div>

        <div class="d-grid gap-2 pt-2">
          <button @click="dialogTipPayVisible$ = true" type="submit" class="btn btn-primary rounded-pill">
            {{$t('label.withdrawal')}}
          </button>
        </div>
        <hr />
        <!-- <div class="processed d-flex justify-content-between align-items-center">
          <div class="d-flex me-3">
            <span> {{$t('label.pendingBalance')}}</span>
          </div>
          <div class="d-flex align-items-center">{{row.pending_balance}}</div>
        </div> -->
      </template>
    </iq-card>
    <iq-card>
      <template v-slot:body>
        <div class="iq-earning-page">
          <ul class="earning-list p-0 m-0">
            <li v-for="(item, index) in filteredList" :key="index" @click="selectProject(item)" :class="`${item.id === selected ? 'active' : ''}`">
              <router-link :to="{ name: item.routeName }"><i :class="`${item.icon}`"></i>{{ item.title }}</router-link>
            </li>
          </ul>
        </div>
      </template>
    </iq-card>
  </div>
  <el-dialog v-model="dialogTipPayVisible$" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :title="$t('申請提款')" :width="`${w}`">
    <div class="row pb-3">
      <div class="col-6 col-md-6 ">
        <h4 ref="cashRef">{{ $t('餘額') }}：{{row.cash}}</h4>
      </div>
      <div class="col-6 col-md-6">
        <h4 ref="suggestBalanceRef">{{ $t('推薦收益') }}：{{row.point}}</h4>
      </div>
    </div>
    <el-form ref="ruleFormRef" :model="form">
      <h5>{{ $t('提款類型') }}</h5>
      <el-select class="w-100 pb-2" :placeholder="$t('請選擇提款類型')" v-model="form.type" @change="select">
        <el-option v-for="item in options" :key="item.value" :value-key="value" :label="item.label" :value="item.value" />
      </el-select>
      <h5>{{ $t('提款金額') }}</h5>
      <el-form-item class="w-100 pb-2" :rules="[{ required: true, message: $t('messages.required') },{ type: 'number', message: 'Is Number' }]" prop="balance_amount">
        <el-input :disabled="disabled" v-model.number="form.balance_amount" type="number" :placeholder="$t('請輸入金額')"></el-input>
      </el-form-item>
      <div class="pay-desc">
        <span class="ps-1">{{ $t('備註：目前餘額只支援一次全部提款。') }}</span>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :loading="onLoading" text @click="dialogTipPayVisible$ = false">{{ $t('label.cancel') }}</el-button>
        <el-button :loading="onLoading" text type="primary" @click="submit()">
          <!-- {{ $t('label.payment') }} -->申請
        </el-button>
      </span>
    </template>

  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { getBalanceDetail, addApply } from '@/api/balance'
import { computed, ref, watch, reactive, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LeftMenu',
  props: ['menuId'],
  setup() {
    const { t } = useI18n()
    const { proxy } = getCurrentInstance()
    const copyText$ = proxy.$copyText
    const toast$ = proxy.$toast
    const store = useStore()
    const dialogTipPayVisible$ = ref(false)
    const disabled = ref(true)
    const onLoading = ref(false)
    const ruleFormRef = ref(null)
    const cashRef = ref(null)
    const suggestBalanceRef = ref(null)
    const options = ref([
      {
        value: 1,
        label: t('餘額')
      },
      {
        value: 2,
        label: t('推薦收益')
      }
    ])

    const form = reactive({
      type: 1,
      balance_amount: ''
    })

    watch(dialogTipPayVisible$, newValue => {
      if (newValue) {
        disabled.value = true
        select(1)
      }
    })

    const select = async type => {
      console.log(type)
      if (type === 1) {
        disabled.value = true
        form.type = 1
        setTimeout(() => {
          form.balance_amount = parseInt(
            cashRef.value.textContent.replace(/[^\d]/g, '')
          )
        }, 300)
      }
      if (type === 2) {
        disabled.value = false
        form.type = 2
        setTimeout(() => {
          form.balance_amount = parseInt(
            suggestBalanceRef.value.textContent.replace(/[^\d]/g, '')
          )
        }, 300)
      }
    }

    const submit = async () => {
      onLoading.value = true
      ruleFormRef.value.validate(valid => {
        addApply(form)
          .then(res => {
            toast$.show(res.message)
            onLoading.value = false
          })
          .catch(err => {
            console.log(err)
            toast$.show(err.response.data.message)
            onLoading.value = false
          })
        if (!valid) onLoading.value = false
        return
        //... 登入～～～.
      })
    }
    const w = computed(() => {
      if (window.innerWidth > 1000 && window.innerWidth < 1200) {
        return '50%'
      } else if (window.innerWidth > 1200 && window.innerWidth < 2000) {
        return '40%'
      } else {
        return window.innerWidth - window.innerWidth / 10 + 'px'
      }
    })
    const onCopyText = r => {
      copyText$(r)
        .then(() => {
          toast$.show('複製成功')
        })
        .catch(() => {
          toast$.show(`can't copy`)
        })
    }
    return {
      onCopyText,
      dialogTipPayVisible$,
      w,
      store,
      submit,
      form,
      ruleFormRef,
      options,
      disabled,
      select,
      cashRef,
      suggestBalanceRef,
      onLoading
    }
  },
  data() {
    return {
      selected: 1,
      menuList: [
        {
          id: 1,
          title: this.$t('label.income'),
          routeName: 'user.earnings',
          icon: 'fa fa-dollar-sign me-2'
        },
        {
          id: 2,
          title: this.$t('label.paymentRequest'),
          routeName: 'user.payouts',
          icon: 'fa fa-university me-2'
        },
        {
          id: 3,
          title: this.$t('label.incomeStatistics'),
          routeName: 'user.report',
          icon: 'fa fa-chart-line me-2'
        },
        {
          id: 4,
          title: this.$t('label.recommendedFriends'),
          routeName: 'user.referrals',
          icon: 'fa fa-people-arrows me-2'
        },
        {
          id: 5,
          title: this.$t('label.recommendedUser'),
          routeName: 'user.referrals-user-list',
          icon: 'fa fa-user-friends me-2'
        }
      ],
      search: '',
      project: {
        id: 0,
        title: ''
      },
      ref: '',
      row: ''
    }
  },
  methods: {
    selectProject() {
      this.selected = this.menuId
    },
    getRow() {
      getBalanceDetail()
        .then(res => {
          this.row = res.data
        })
        .catch(err => {
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  },
  computed: {
    filteredList() {
      return this.menuList.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  created() {
    this.selected = this.menuId
    this.getRow()
    this.ref = `${process.env.VUE_APP_FRONT_END_URL}auth/signup?ref=${this.user.account}`
  },
  mounted() {}
}
</script>
